/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Councillors"), "\n", React.createElement("table", {
    role: "presentation"
  }, React.createElement("thead", null, React.createElement("tr", null, React.createElement("th", null, "Councillor Name"), "\n", React.createElement("th", null, "Role(s)"), "\n", React.createElement("th", null, "Contact Details"))), React.createElement("tbody", null, React.createElement("tr", null, React.createElement("td", null, "Cllr Simon Barnes"), React.createElement("td"), React.createElement("td", null, React.createElement("a", {
    href: "tel:+441724721443"
  }, React.createElement("i", {
    class: "fas fa-phone-square-alt"
  }), " : 01724 721443"), React.createElement("br"), React.createElement("a", {
    href: "mailto:simonbarnes@burtonuponstatherparishcouncil.gov.uk"
  }, React.createElement("i", {
    class: "fas fa-at"
  }), " : simonbarnes@burtonuponstatherparishcouncil.gov.uk"))), React.createElement("tr", null, React.createElement("td", null, "Cllr Peter Bell"), React.createElement("td"), React.createElement("td", null, React.createElement("a", {
    href: "tel:+441724720327"
  }, React.createElement("i", {
    class: "fas fa-phone-square-alt"
  }), " : 01724 720327"), React.createElement("br"), React.createElement("a", {
    href: "mailto:peterfredbell@outlook.com"
  }, React.createElement("i", {
    class: "fas fa-at"
  }), " : peterfredbell@outlook.com"))), React.createElement("tr", null, React.createElement("td", null, "Cllr Stephanie Bull"), "\n", React.createElement("td", null, "Burton United Football Club"), React.createElement("td", null, React.createElement("a", {
    href: "tel:+441724720284"
  }, React.createElement("i", {
    class: "fas fa-phone-square-alt"
  }), " : 01724 720284"), React.createElement("br"), React.createElement("a", {
    href: "tel:+447734819441"
  }, React.createElement("i", {
    class: "fas fa-mobile"
  }), " : 07734819441"), React.createElement("br"), React.createElement("a", {
    href: "mailto:stephbull@burtonuponstatherparishcouncil.gov.uk"
  }, React.createElement("i", {
    class: "fas fa-at"
  }), " : stephbull@burtonuponstatherparishcouncil.gov.uk"))), React.createElement("tr", null, React.createElement("td", null, "Cllr Cathy Harber"), "\n", React.createElement("td", null, "ERNLLCA District Meetings", React.createElement("br"), "NATS"), React.createElement("td", null, React.createElement("a", {
    href: "tel:+447854529379"
  }, React.createElement("i", {
    class: "fas fa-mobile"
  }), " : 07854529379"), React.createElement("br"), React.createElement("a", {
    href: "tel:+441724720289"
  }, React.createElement("i", {
    class: "fas fa-phone-square-alt"
  }), " : 01724 720289"), React.createElement("br"), React.createElement("a", {
    href: "mailto:cathyharber@burtonuponstatherparishcouncil.gov.uk"
  }, React.createElement("i", {
    class: "fas fa-at"
  }), " : cathyharber@burtonuponstatherparishcouncil.gov.uk"))), React.createElement("tr", null, React.createElement("td", null, "Cllr Jim Mitchell"), "\n", React.createElement("td", null, "Vice Chair of the Council", React.createElement("br"), "StoptheWharf"), React.createElement("td", null, React.createElement("a", {
    href: "tel:+447721507770"
  }, React.createElement("i", {
    class: "fas fa-mobile"
  }), " : 07721507770"), React.createElement("br"), React.createElement("a", {
    href: "mailto:jim.mitchell@buspc.org"
  }, React.createElement("i", {
    class: "fas fa-at"
  }), " : jim.mitchell@buspc.org"))), React.createElement("tr", null, React.createElement("td", null, "Cllr Simon Nicholson"), "\n", React.createElement("td", null, "Roxby Landfill", React.createElement("br"), "StoptheWharf", React.createElement("br"), "Burton United Football Club"), React.createElement("td", null, React.createElement("a", {
    href: "tel:+447450006564"
  }, React.createElement("i", {
    class: "fas fa-mobile"
  }), " : 07450006564"), React.createElement("br"), React.createElement("a", {
    href: "mailto:simonnicholson@burtonuponstatherparishcouncil.gov.uk"
  }, React.createElement("i", {
    class: "fas fa-at"
  }), " : simonnicholson@burtonuponstatherparishcouncil.gov.uk"))), React.createElement("tr", null, React.createElement("td", null, "Cllr Elliott O'Roarty"), React.createElement("td"), React.createElement("td", null, React.createElement("a", {
    href: "tel:+447901337879"
  }, React.createElement("i", {
    class: "fas fa-mobile"
  }), " : 07901337879"), React.createElement("br"), React.createElement("a", {
    href: "mailto:oroarty1234@outlook.com"
  }, React.createElement("i", {
    class: "fas fa-at"
  }), " : oroarty1234@outlook.com"))), React.createElement("tr", null, React.createElement("td", null, "Cllr Penny Pringle"), "\n", React.createElement("td", null, "Chair of the Council", React.createElement("br"), "NATS", React.createElement("br"), "NLC Town & Parish Liaison"), React.createElement("td", null, React.createElement("a", {
    href: "tel:+447572132429"
  }, React.createElement("i", {
    class: "fas fa-mobile"
  }), " : 07572132429"), React.createElement("br"), React.createElement("a", {
    href: "mailto:pennypringle@burtonuponstatherparishcouncil.gov.uk"
  }, React.createElement("i", {
    class: "fas fa-at"
  }), " : pennypringle@burtonuponstatherparishcouncil.gov.uk"))), React.createElement("tr", null, React.createElement("td", null, "Cllr Rob Tuplin"), React.createElement("td"), React.createElement("td", null, React.createElement("a", {
    href: "tel:+447984613515"
  }, React.createElement("i", {
    class: "fas fa-mobile"
  }), " : 07984613515"), React.createElement("br"), React.createElement("a", {
    href: "mailto:robtuplin@burtonuponstatherparishcouncil.gov.uk"
  }, React.createElement("i", {
    class: "fas fa-at"
  }), " : robtuplin@burtonuponstatherparishcouncil.gov.uk"))), React.createElement("tr", null, React.createElement("td", null, "Cllr Rachel Webber"), "\n", React.createElement("td", null, "ERNLLCA district meetings", React.createElement("br"), "NLC Town & Parish Liaison"), React.createElement("td", null, React.createElement("a", {
    href: "tel:+447773442229"
  }, React.createElement("i", {
    class: "fas fa-mobile"
  }), " : 07773442229"), React.createElement("br"), React.createElement("a", {
    href: "mailto:rachelwebber@burtonuponstatherparishcouncil.gov.uk"
  }, React.createElement("i", {
    class: "fas fa-at"
  }), " : rachelwebber@burtonuponstatherparishcouncil.gov.uk"))), React.createElement("tr", null, React.createElement("td", null, "Mrs Candace Brent"), "\n", React.createElement("td", null, "Clerk to the Parish Council"), React.createElement("td", null, React.createElement("a", {
    href: "tel:+441724489981"
  }, React.createElement("i", {
    class: "fas fa-phone-square-alt"
  }), " : 01724 489981"), React.createElement("br"), React.createElement("a", {
    href: "tel:+447982265914"
  }, React.createElement("i", {
    class: "fas fa-mobile"
  }), " : 07982265914"), React.createElement("br"), React.createElement("a", {
    href: "mailto:clerk@burtonuponstatherparishcouncil.gov.uk"
  }, React.createElement("i", {
    class: "fas fa-at"
  }), " : clerk@burtonuponstatherparishcouncil.gov.uk"), React.createElement("br"), React.createElement("a", {
    href: "mailto:clerk.buspc@hotmail.co.uk"
  }, React.createElement("i", {
    class: "fas fa-at"
  }), " : clerk.buspc@hotmail.co.uk"))))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
